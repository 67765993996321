//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex';
import * as api from '$network/api.js';
import FloatingInteractive from '$components/ui/floating-interactive.vue';

export default {
  components: {
    FloatingInteractive
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations('auth', ['setLoggedStatus']),
    async logout() {
      const { $refs } = this;
      const { floatingInteractive } = $refs;

      floatingInteractive.close();
      await api.logout();
      window.localStorage.removeItem('z-tabs-sync');
      // window.location.reload();
    }
  }
};
