//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

import NavigationMenu from '$components/ui/navigation-menu.vue';

export default {
  name: 'App',
  components: {
    NavigationMenu
  },
  computed: {
    ...mapState('ui', ['cachedTabs']),
    allowedToCache() {
      if (Object.values(this.cachedTabs).length === 0) {
        return /(.*)/;
      }

      return Object.values(this.cachedTabs);
    }
  },
  created() {
    // console.log(this.$router.options.routes.map(r => r.path));
  }
};
