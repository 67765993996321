/* eslint-disable import/prefer-default-export */
export const getAppDomain = () => {
  let { VUE_APP_URL } = process.env;

  const URI = `${window.location.protocol}//${window.location.hostname}`;

  if (VUE_APP_URL !== URI) {
    VUE_APP_URL = URI;
  }

  return VUE_APP_URL;
};
