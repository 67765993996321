export const injectHeader = axios => {
  axios.interceptors.request.use(
    config => {
      const storedToken = window.localStorage.getItem('bearer.token');

      if (!storedToken) return config;

      const { headers: originalHeaders } = config;
      const headers = { ...originalHeaders, Authorization: `Bearer ${storedToken}` };
      return { ...config, headers };
    },
    error => Promise.reject(error)
  );
};

export const storeToken = axios => {
  axios.interceptors.response.use(
    response => {
      const { config, data } = response;

      if (config.url.indexOf('/api/login') !== -1) {
        const { token } = data.content;
        window.localStorage.setItem('bearer.token', token);
        return response;
      }

      if (config.url.indexOf('/api/logout') !== -1 || response.status === 401) {
        window.localStorage.removeItem('bearer.token');
        // window.location.reload();
        return response;
      }

      return response;
    },
    error => Promise.reject(error)
  );
};
