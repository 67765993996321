//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapMutations } from 'vuex';
import links from '../../menu.yaml';
import { normalize } from '$lib/utils.js';

import InputText from './input-text.vue';
import NavigationMenuLink from './navigation-menu-link.vue';
import NavigationMenuUser from './navigation-menu-user.vue';

const searchNormalized = (string, search) =>
  normalize(string)
    .toLowerCase()
    .indexOf(normalize(search).toLowerCase()) !== -1;

export default {
  components: {
    InputText,
    NavigationMenuLink,
    NavigationMenuUser
  },
  data: () => ({
    links,
    search: ''
  }),
  computed: {
    ...mapState('ui', ['showNavigationMenu', 'actives']),
    ...mapState('common', ['user']),
    filteredByPermissions() {
      const { links: storedLinks, permissions } = this;
      const linkHavePermissions = link =>
        link.permissions.some(permissionString =>
          permissions.some(permission => permission === permissionString)
        );
      const getFilteredChilds = childs =>
        childs.reduce((acc, child) => {
          if (!child.permissions) {
            acc.push(child);
            return acc;
          }

          if (!linkHavePermissions(child)) {
            return acc;
          }

          acc.push(child);
          return acc;
        }, []);

      return storedLinks.reduce((acc, link) => {
        // Si no tiene hijos, ni permisos, el link es valido
        if (!link.permissions && !link.childs) {
          acc.push(link);
          return acc;
        }

        // Si tiene permisos, no validos, no añadir
        if (link.permissions && !linkHavePermissions(link)) {
          return acc;
        }

        // Si tiene hijos, comprobar los permisos de los hijos
        if (link.childs) {
          acc.push({ ...link, childs: getFilteredChilds(link.childs) });
          return acc;
        }

        acc.push(link);
        return acc;
      }, []);
    },
    filteredBySearch() {
      const { search, filteredByPermissions } = this;

      if (search.length === 0) {
        return filteredByPermissions;
      }

      return filteredByPermissions.reduce((acc, link) => {
        // Busqueda padres
        if (searchNormalized(link.name, search)) {
          acc.push(link);
          return acc;
        }

        // Sin coincidencias y sin hijos no se continua
        if (!link.childs) {
          return acc;
        }

        // Buscar en hijos
        const filteredChilds = link.childs.filter(child => searchNormalized(child.name, search));

        if (filteredChilds.length !== 0) {
          acc.push({ ...link, forceOpen: true, childs: filteredChilds });
        }

        return acc;
      }, []);
    },
    mappedList() {
      const { filteredBySearch, actives } = this;
      const tabsActives = Object.keys(actives);

      return filteredBySearch.map(link => ({ ...link, active: tabsActives.includes(link.path) }));
    },
    permissions() {
      if (!this.user || !this.user.roles) {
        return [];
      }

      const reduced = this.user.roles.reduce((acc, role) => {
        role.permissions.forEach(permission => {
          if (!acc[permission.name]) {
            acc[permission.name] = true;
          }
        });

        return acc;
      }, {});

      return Object.keys(reduced);
    }
  },
  methods: {
    ...mapMutations('ui', ['setNavigationMenuStatus']),
    onClickOutside({ target }) {
      const { showNavigationMenu, setNavigationMenuStatus } = this;
      const element = target;

      if (!showNavigationMenu) {
        return;
      }

      if (!element) {
        setNavigationMenuStatus(false);
      }

      if (element.classList.contains('disable-menu-close')) {
        return;
      }

      if (
        document.querySelector('.navigation-menu').contains(element) ||
        element.parentNode.parentNode.classList.contains('link-with-childs') ||
        element.parentNode.parentNode.classList.contains('links')
      ) {
        return;
      }

      setNavigationMenuStatus(false);
    }
  }
};
