//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import SlideUpDown from 'vue-slide-up-down';

export default {
  components: {
    SlideUpDown
  },
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    open: false
  }),
  computed: {
    ...mapState('ui', ['actives']),
    withActiveChild() {
      const { childs } = this.link;
      const activePaths = Object.values(this.actives).map(active => active.path);
      return childs.some(child => activePaths.includes(child.path));
    }
  },
  watch: {
    'link.forceOpen': function(current) {
      const { link } = this;

      if (!link.childs) {
        return;
      }

      this.open = current === true;
    }
  },
  mounted() {
    this.open = this.link.forceOpen === true;
  },
  methods: {
    toggleChilds() {
      this.open = !this.open;
    }
  }
};
