import axios from 'axios';
import errorNotifications from '$lib/axios-interceptors/error-notifications-interceptor.js';
import { injectHeader, storeToken } from '$lib/axios-interceptors/token-support-interceptor.js';
import { getAppDomain } from '$lib/domain.js';

axios.create({
  baseURL: getAppDomain()
});

injectHeader(axios);
storeToken(axios);
errorNotifications(axios);

export const login = body => axios.post(`/api/login`, body);
export const logout = () => axios.post(`/api/logout`);
export const fetchUser = () => axios.get(`/api/user`);

export const createLead = (channel, agentIdentification) =>
  axios.post(`/api/lead`, { channel, agentIdentification });
export const createExistingLead = (channel, leadId, uid) =>
  axios.post(`/api/lead/${leadId}/uid/${uid}`, { channel });

export const checkScoring = (uid, documentNumber) =>
  axios.get(`/api/lead/${uid}/scoring/${documentNumber}`);

export const resendDigitalSign = uid => axios.get(`/api/lead/${uid}/digital-sign`);

// Comprobar existencia de Bateria Virtual
export const existBateriaVirtual = (uuid, nif, ownerDocument) =>
  axios.get(`/api/virtual-battery/${uuid}/${nif}/${ownerDocument}`);

// download documentos
export const downloadDocuments = (uid, filename) =>
  axios.get(`/api/files/download/leads/${uid}/${filename} `, { responseType: 'blob' });

// Procesar factura
export const procesarFactura = (lead, body) =>
  axios.post(`/api/files/uploads/invoices/leads/${lead}`, body);

// Calcular scoring
export const calcularScoring = body => axios.post(`/api/scoring-multiple`, body);

// Scoring activo
export const scoringActivo = () => axios.get(`/api/configuracion-scoring`);
