//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TippyComponent as Tippy } from 'vue-tippy';

export default {
  components: {
    Tippy
  },
  props: {
    trigger: {
      type: String,
      default: 'click'
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    theme: {
      type: String,
      default: 'light'
    },
    arrow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      const { tip } = this.$refs.tippy;

      tip.hide();
    }
  }
};
