//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    placeholder: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    password: {
      type: Boolean
    },
    border: {
      type: Boolean
    }
  },
  data: () => ({
    stored: ''
  }),
  watch: {
    stored() {
      this.$emit('input', this.stored);
    },
    value() {
      this.stored = this.value;
    }
  }
};
